import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

import "core-js/modules/es.array.push.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c(VApp, {
    staticStyle: {
      "height": "600px"
    }
  }, [_c('inc-header'), _c(VContainer, {
    staticClass: "pt-10 grey lighten-4",
    staticStyle: {
      "max-width": "920px"
    },
    attrs: {
      "grid-list-xs": ""
    }
  }, [(_vm.goback.url != '' ? true : false) ? _c(VSubheader, {
    staticClass: "px-0 mb-4"
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.gotoUrl(_vm.goback.url);
      }
    }
  }, [_vm._v(" 返回 " + _vm._s(_vm.goback.name) + " "), _c(VIcon, {
    staticClass: "ml-2"
  }, [_vm._v("mdi-link")])], 1)], 1) : _vm._e(), _vm.usinfo.TARGETID ? _c(VCard, {
    attrs: {
      "flat": "",
      "tile": "",
      "id": "change"
    }
  }, [_c(VCardTitle, {
    staticClass: "pb-0",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.usinfo.NAME) + " " + _vm._s(_vm.usinfo.TYPE == "teacher" ? "老师" : "同学") + " ")]), _c(VCardText, {
    staticClass: "pt-0"
  }, [_vm._v(" " + _vm._s(_vm.usinfo.SCHOOLNAME) + " ")]), _c(VCardText, [_vm.teacher.length + _vm.student.length + _vm.parents.length > 1 ? _c(VBtn, {
    attrs: {
      "tile": "",
      "dark": "",
      "elevation": "1",
      "color": "orange darken-3",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = true;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-swap-horizontal")]), _vm._v(" 却换身份 ")], 1) : _vm._e()], 1)], 1) : _c(VCard, {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c(VCardTitle, {
    staticClass: "red--text",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" 你还未关联用户身份 ")]), _c(VCardText, [_vm._v(" 注意：关联用户身份才能进入学校社区，请在用户信息里完成身份操作 ")]), _c(VCardText, [_c('p', [_vm._v("1、教师、学生请在“用户设置”的“用户信息”完成关联")]), _c('p', [_vm._v("2、家长请在“用户设置”的“监护人”完成关联")])])], 1), _c(VDivider), _c(VCard, {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c(VCardText, {
    staticClass: "px-2"
  }, [_c(VRow, _vm._l(_vm.items, function (item, x) {
    return _c(VCol, {
      key: x,
      attrs: {
        "cols": "4",
        "md": "3"
      }
    }, [_c(VCard, {
      attrs: {
        "flat": "",
        "id": item.id
      }
    }, [_c(VCardText, {
      staticClass: "text-center pb-2"
    }, [_c(VBtn, {
      attrs: {
        "color": item.color,
        "elevation": "1",
        "fab": "",
        "dark": "",
        "large": ""
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push({
            path: item.path
          });
        }
      }
    }, [_c(VIcon, {
      attrs: {
        "large": ""
      }
    }, [_vm._v(_vm._s(item.icon))])], 1)], 1), _c(VCardText, {
      staticClass: "text-center subtitle-1 font-weight-bold py-0"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)], 1);
  }), 1)], 1)], 1), _c(VDialog, {
    attrs: {
      "scrollable": "",
      "fullscreen": _vm.mobile,
      "persistent": "",
      "overlay": false,
      "max-width": "500px",
      "transition": "dialog-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "pr-2",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" 切换身份 "), _c(VSpacer), _c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1), _c(VDivider), _c(VCardText, {
    staticClass: "py-4"
  }, [_c(VRow, [Object.keys(_vm.teacher).length > 0 ? _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.teacher, function (item, x) {
    return _c(VCard, {
      key: x,
      attrs: {
        "tile": "",
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.reusrec('T' + item.id);
        }
      }
    }, [_c(VCardTitle, {
      staticClass: "pb-0",
      attrs: {
        "primary-title": ""
      }
    }, [_vm._v(" " + _vm._s(item.name) + "老师 ")]), _c(VCardText, {
      staticClass: "pt-0"
    }, [_vm._v(" " + _vm._s(_vm.school[item.school].name) + " ")])], 1);
  }), 1) : _vm._e(), Object.keys(_vm.student).length > 0 ? _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.student, function (item, y) {
    return _c(VCard, {
      key: y,
      attrs: {
        "tile": "",
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.reusrec('S' + item.id);
        }
      }
    }, [_c(VCardTitle, {
      staticClass: "text-h8",
      attrs: {
        "primary-title": ""
      }
    }, [_vm._v(" " + _vm._s(item.name) + "同学 ")]), _c(VCardText, {
      staticClass: "pt-0"
    }, [_vm._v(" " + _vm._s(_vm.school[item.school].name) + " ")])], 1);
  }), 1) : _vm._e(), _vm.parents.length > 0 ? _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.parents, function (item, x) {
    return _c(VCard, {
      key: x,
      attrs: {
        "tile": "",
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.reusrec('S' + item.id);
        }
      }
    }, [_c(VCardTitle, {
      staticClass: "text-h8",
      attrs: {
        "primary-title": ""
      }
    }, [_vm._v(" " + _vm._s(item.name) + "的家长 ")]), _c(VCardText, {
      staticClass: "pt-0"
    }, [_vm._v(" " + _vm._s(_vm.school[item.school].name) + " ")])], 1);
  }), 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };