import "core-js/modules/es.array.push.js";
import incHeader from "../inc-header.vue";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
export default {
  components: {
    incHeader
  },
  props: {
    mobile: Boolean
  },

  data() {
    return {
      usinfo: {},
      teacher: [],
      student: [],
      parents: [],
      openuid: "",
      school: null,
      dialog: false,
      items: [{
        id: "user",
        title: "用户信息",
        color: "success darken-1",
        subtitle: `<span class="text--primary">Ali Connors</span>`,
        icon: "mdi-card-account-details-outline",
        path: "/user/usinfo"
      }, {
        id: "passwd",
        title: "密码修改",
        color: "deep-purple darken-1",
        subtitle: `<span class="text--primary">Ali Connors</span>`,
        icon: "mdi-key-variant",
        path: "/user/passwd"
      }, {
        id: "mobile",
        title: "手机号码",
        color: "blue-grey darken-1",
        subtitle: `<span class="text--primary">Ali Connors</span>`,
        icon: "mdi-cellphone",
        path: "/user/phone"
      }, {
        id: "parent",
        title: "监护人",
        color: "blue darken-1",
        subtitle: `<span class="text--primary">Ali Connors</span>`,
        icon: "mdi-human-male-boy",
        path: "/user/parent"
      }],
      goback: {}
    };
  },

  created() {},

  mounted() {
    this.reusrec();
    let goback = localStorage.getItem("goback");
    if (goback != undefined) this.goback = JSON.parse(goback);
    setTimeout(() => {
      this.loadHelp();

      if (this.$route.query.goback) {
        localStorage.setItem("goback", this.$route.query.goback);
        this.goback = JSON.parse(this.$route.query.goback);
      }
    }, 500);
  },

  methods: {
    help() {
      const driver = new Driver({
        doneBtnText: "完成",
        // Text on the final button
        closeBtnText: "关闭",
        // Text on the close button for this step
        stageBackground: "grey",
        // Background color for the staged behind highlighted element
        allowClose: false,
        nextBtnText: "下一步",
        // Next button text for this step
        prevBtnText: "上一步" // Previous button text for this step

      });
      driver.defineSteps([{
        element: "#home-menu",
        popover: {
          title: "<h6>这是主菜单</em>",
          description: "左边按钮可以弹出左侧菜单列表，右边按钮可以提供页面帮助"
        }
      }, {
        element: "#hmenu",
        popover: {
          title: "<h6>这是主菜单</em>",
          description: "左边按钮可以弹出左侧菜单列表，右边按钮可以提供页面帮助"
        }
      }, {
        element: "#change",
        popover: {
          title: "当用户为多个身份时，可以切换",
          description: "监护人可以增设多个子女，并在这里切换不同的子女身份。"
        }
      }, {
        element: "#user",
        popover: {
          title: "用户真实信息",
          description: "用户本人的身份信息，教师及学生在这里设定自己信息并关联自己的学校。"
        }
      }, {
        element: "#parent",
        popover: {
          title: "添加自己的监护信息",
          description: "家长或者教师身份的家长均可在这里增加监护信息（子女），添加成功后，可关联孩子的学校教学服务。"
        }
      }, {
        element: "#help",
        popover: {
          title: "帮助按钮",
          description: "这里是页面帮助指引",
          position: "bottom-right"
        }
      }]);
      driver.start();
    },

    loadHelp() {
      let that = this;
      this.$sopen.getHelp(220215).then(function (res) {
        if (res.errmsg == "none") {
          that.help();
        }
      });
    },

    reusrec(targetid = "") {
      let tokenValue = this.$sopen.getCookie();
      this.openuid = tokenValue.urec;
      let data = {
        module: "usinfo",
        title: "reusrec",
        data: {
          cookie: tokenValue,
          targetid: targetid,
          url: document.location.protocol + "//" + (window.location.host.indexOf("schoolopen") > -1 ? window.location.host : "luohu.sopen.cn")
        }
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        that.usinfo = res.data.usinfo.usinfo;

        if (that.usinfo.INFO.teacher && that.usinfo.INFO.teacher.data) {
          for (let x in that.usinfo.INFO.teacher.data) {
            that.teacher.push(that.usinfo.INFO.teacher.data[x]);
          }
        }

        if (that.usinfo.INFO.student && that.usinfo.INFO.student.data) {
          for (let x in that.usinfo.INFO.student.data) {
            that.student.push(that.usinfo.INFO.student.data[x]);
          }
        }

        if (that.usinfo.INFO.parents && that.usinfo.INFO.parents.data) {
          for (let x in that.usinfo.INFO.parents.data) {
            that.parents.push(that.usinfo.INFO.parents.data[x]);
          }
        }

        if (that.usinfo.INFO.school && that.usinfo.INFO.school.data) {
          that.school = that.usinfo.INFO.school.data;
        }

        if (targetid != "") {
          ////console.log("/token?openuid=" + res.data.usinfo.openuid);

          /*
          that.$router.push({
            path: "/token?openuid=" + res.data.usinfo.openuid,
          });
          //*/
          that.dialog = false;
          that.target(res.data.usinfo.openuid);
        }
      });
    },

    target(openuid) {
      let data = {
        module: "user_info",
        title: "setcookie",
        res: openuid
      }; //console.log(data);

      this.$sopen.requestApi(data).then(function (res) {
        sessionStorage.setItem("uskey", JSON.stringify(res.errmsg)); //window.location.reload();

        /*
        that.$router.push({
          path: "/home/",
        });
        //*/
      });
    },

    gotoUrl(url) {
      console.log(url);
      window.location.href = url;
    }

  }
};